import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getCurrentUserRole } from '../firebase/userService';
import { 
  FaHome, 
  FaBuilding, 
  FaUsers, 
  FaTools, 
  FaPoundSign,
  FaChartLine,
  FaUserTie,
  FaSignOutAlt,
  FaBars,
  FaChevronLeft,
  FaChevronRight,
  FaChevronDown,
  FaTimes,
  FaComments
} from 'react-icons/fa';
import { 
  Box, 
  List, 
  ListItem, 
  ListItemIcon, 
  ListItemText, 
  ListItemButton,
  IconButton, 
  Drawer, 
  styled,
  Typography,
  Collapse,
  Avatar,
  Divider,
  Alert,
  CircularProgress
} from '@mui/material';
import { logout } from '../firebase/authService';
import { useAuth } from '../contexts/AuthContext';
import { useSidebar } from '../contexts/SidebarContext';
import { doc, onSnapshot, setDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { useTheme } from '../contexts/ThemeContext';

const DRAWER_WIDTH = 260;
const COLLAPSED_WIDTH = 64;

const StyledSidebar = styled(Box)(({ theme, ownerState }) => ({
  width: ownerState.isCollapsed ? COLLAPSED_WIDTH : DRAWER_WIDTH,
  position: 'fixed',
  top: '64px',
  left: 0,
  bottom: 0,
  backgroundColor: theme.palette.background.paper,
  borderRight: `1px solid ${theme.palette.divider}`,
  transition: theme.transitions.create(['width', 'transform'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  overflowY: 'auto',
  [theme.breakpoints.down('sm')]: {
    width: DRAWER_WIDTH,
    transform: ownerState.isMobileOpen ? 'translateX(0)' : `translateX(-${DRAWER_WIDTH}px)`,
    top: 0,
    zIndex: theme.zIndex.drawer + 1,
  }
}));

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  marginBottom: '4px',
  marginLeft: '8px',
  marginRight: '8px',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.action.selected,
    '&:hover': {
      backgroundColor: theme.palette.action.selected,
    },
    '& .MuiListItemIcon-root': {
      color: theme.palette.primary.main,
    },
    '& .MuiListItemText-primary': {
      color: theme.palette.primary.main,
      fontWeight: 500,
    },
  },
  '& .MuiListItemIcon-root': {
    minWidth: 40,
    color: theme.palette.text.secondary,
  },
  '& .MuiListItemText-primary': {
    fontSize: '0.875rem',
  },
}));

const SubMenuListItemButton = styled(ListItemButton)(({ theme }) => ({
  paddingLeft: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  marginBottom: '2px',
  marginLeft: '8px',
  marginRight: '8px',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.action.selected,
    '& .MuiTypography-root': {
      color: theme.palette.primary.main,
      fontWeight: 500,
    },
  },
}));

const LogoContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isCollapsed'
})(({ theme, isCollapsed }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '64px',
  borderBottom: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
  '& img': {
    maxWidth: isCollapsed ? '32px' : '160px',
    maxHeight: '40px',
    transition: theme.transitions.create(['max-width'], {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

const SafeListItemButton = React.forwardRef(({ button, ...props }, ref) => (
  <ListItemButton ref={ref} {...props} />
));

const Sidebar = () => {
  const { theme } = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useAuth();
  const { isCollapsed: sidebarIsCollapsed, setIsCollapsed: setSidebarIsCollapsed } = useSidebar();
  const [activeSubmenu, setActiveSubmenu] = useState(null);
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const [logoUrl, setLogoUrl] = useState(() => {
    const storedUrl = localStorage.getItem('appLogoUrl');
    return storedUrl || null;
  });
  const [isLogoLoading, setIsLogoLoading] = useState(false);
  const [logoError, setLogoError] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const logoSubscriptionRef = useRef(null);

  const isActive = (path) => {
    if (path === '/') {
      return location.pathname === '/';
    }
    return location.pathname.startsWith(path);
  };

  const handleSubmenuClick = (title) => {
    setActiveSubmenu(activeSubmenu === title ? null : title);
  };

  const toggleMobileDrawer = () => {
    setIsMobileOpen(!isMobileOpen);
  };

  const handleNavigation = (path, hasSubmenu, title) => {
    if (hasSubmenu) {
      handleSubmenuClick(title);
    } else {
      if (window.innerWidth < 600) { // Close drawer on mobile only
        setIsMobileOpen(false);
      }
      navigate(path);
    }
  };

  const handleLogout = async () => {
    try {
      if (window.innerWidth < 600) {
        setIsMobileOpen(false);
      }
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  useEffect(() => {
    if (!currentUser) return;

    const settingsRef = doc(db, 'settings', 'global');
    setIsLogoLoading(true);

    const unsubscribe = onSnapshot(
      settingsRef,
      async (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          const newLogoUrl = data?.branding?.logoUrl;

          // Only update if the URL has actually changed
          if (newLogoUrl !== logoUrl) {
            if (newLogoUrl) {
              try {
                // Create a promise that resolves when the image loads or rejects after 10 seconds
                const loadImage = () => new Promise((resolve, reject) => {
                  const img = new Image();
                  const timeout = setTimeout(() => {
                    img.src = '';  // Cancel the image load
                    reject(new Error('Image load timed out'));
                  }, 10000);  // 10 second timeout

                  img.onload = () => {
                    clearTimeout(timeout);
                    resolve(newLogoUrl);
                  };

                  img.onerror = () => {
                    clearTimeout(timeout);
                    reject(new Error('Failed to load image'));
                  };

                  img.src = newLogoUrl;
                });

                // Try to load the image up to 3 times
                for (let attempt = 1; attempt <= 3; attempt++) {
                  try {
                    await loadImage();
                    setLogoUrl(newLogoUrl);
                    localStorage.setItem('appLogoUrl', newLogoUrl);
                    setLogoError(false);
                    break;
                  } catch (error) {
                    console.warn(`Failed to load logo (attempt ${attempt}/3):`, error);
                    if (attempt === 3) {
                      throw error;
                    }
                    // Wait before retrying (exponential backoff)
                    await new Promise(resolve => setTimeout(resolve, Math.pow(2, attempt) * 1000));
                  }
                }
              } catch (error) {
                console.error('Failed to load logo after retries:', error);
                setLogoError(true);
                // Keep the old logo URL if it exists
                if (!logoUrl) {
                  setLogoUrl(null);
                  localStorage.removeItem('appLogoUrl');
                }
              }
            } else {
              setLogoUrl(null);
              localStorage.removeItem('appLogoUrl');
              setLogoError(false);
            }
          }
          setIsLogoLoading(false);
        }
      },
      (error) => {
        console.error('Error in logo snapshot listener:', error);
        setIsLogoLoading(false);
      }
    );

    logoSubscriptionRef.current = unsubscribe;
    return () => {
      if (logoSubscriptionRef.current) {
        logoSubscriptionRef.current();
      }
    };
  }, [currentUser, logoUrl]);

  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        const role = await getCurrentUserRole();
        setUserRole(role);
      } catch (error) {
        console.error('Error fetching user role:', error);
      }
    };

    if (currentUser) {
      fetchUserRole();
    }
  }, [currentUser]);

  // Preserve logo state during navigation
  useEffect(() => {
    const storedUrl = localStorage.getItem('appLogoUrl');
    if (storedUrl && !logoUrl) {
      setLogoUrl(storedUrl);
    }
  }, [logoUrl]);

  // Clean up on unmount
  useEffect(() => {
    return () => {
      if (logoSubscriptionRef.current) {
        logoSubscriptionRef.current();
      }
    };
  }, []);

  const menuItems = [
    { 
      title: 'Dashboard', 
      path: '/', 
      icon: <FaHome size={20} /> 
    },
    { 
      title: 'Properties',
      path: '/properties',
      icon: <FaBuilding size={20} />,
      submenu: userRole === 'landlord' 
        ? [{ title: 'All Properties', path: '/properties' }]
        : [
            { title: 'All Properties', path: '/properties' },
            { title: 'Add Property', path: '/properties/add' }
          ]
    },
    ...(userRole !== 'landlord' ? [{ 
      title: 'Tenants',
      path: '/tenants',
      icon: <FaUsers size={20} />,
      submenu: [
        { title: 'All Tenants', path: '/tenants' },
        { title: 'Add Tenant', path: '/tenants/add' },
      ]
    }] : []),
    { 
      title: 'Messages',
      path: '/messages',
      icon: <FaComments size={20} />
    },
    { 
      title: 'Maintenance',
      path: '/maintenance',
      icon: <FaTools size={20} /> 
    },
    { 
      title: 'Finance',
      path: '/finance',
      icon: <FaPoundSign size={20} />
    },
    { 
      title: 'Reports',
      path: '/reports',
      icon: <FaChartLine size={20} /> 
    },
    ...(userRole && ['admin', 'agent', 'administrator'].includes(userRole) ? [{
      title: 'Landlords',
      path: '/landlords',
      icon: <FaUserTie size={20} />,
      submenu: [
        { title: 'All Landlords', path: '/landlords' },
        { title: 'Add Landlord', path: '/landlords/add' },
      ]
    }] : [])
  ];

  const drawer = (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <LogoContainer isCollapsed={sidebarIsCollapsed}>
        <Link to="/settings" style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
          {isLogoLoading ? (
            <CircularProgress size={24} />
          ) : logoUrl ? (
            <img 
              src={logoUrl} 
              alt="Company Logo" 
              onError={() => {
                console.error('Logo image failed to load');
                setLogoUrl(null);
              }} 
            />
          ) : (
            <Typography 
              variant="h6" 
              className="placeholder-text"
              color="textSecondary"
            >
              {sidebarIsCollapsed ? 'P' : 'Plutoo'}
            </Typography>
          )}
        </Link>
      </LogoContainer>

      <Divider sx={{ my: 1 }} />

      <List sx={{ flexGrow: 1, overflow: 'auto', py: 0 }}>
        {menuItems.map((item) => (
          <React.Fragment key={item.title}>
            <SafeListItemButton
              component={Link}
              to={item.path}
              selected={isActive(item.path)}
              onClick={() => handleNavigation(item.path, Boolean(item.submenu), item.title)}
              sx={{
                borderRadius: 1,
                mb: 0.5,
                mx: 1,
                '&.Mui-selected': {
                  backgroundColor: 'action.selected',
                  '& .MuiListItemIcon-root': {
                    color: 'primary.main',
                  },
                  '& .MuiTypography-root': {
                    color: 'primary.main',
                    fontWeight: 600,
                  },
                },
              }}
            >
              <ListItemIcon>
                {item.icon}
              </ListItemIcon>
              <ListItemText 
                primary={item.title}
                sx={{ 
                  opacity: sidebarIsCollapsed ? 0 : 1,
                  transition: theme.transitions.create(['opacity'], {
                    duration: theme.transitions.duration.shorter,
                  }),
                }} 
              />
              {!sidebarIsCollapsed && item.submenu && (
                <FaChevronDown
                  style={{
                    transform: activeSubmenu === item.title ? 'rotate(180deg)' : 'none',
                    transition: 'transform 0.3s ease',
                  }}
                />
              )}
            </SafeListItemButton>
            {!sidebarIsCollapsed && item.submenu && activeSubmenu === item.title && (
              <Collapse in={true} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {item.submenu.map((subItem) => (
                    <SafeListItemButton
                      key={subItem.title}
                      component={Link}
                      to={subItem.path}
                      selected={location.pathname === subItem.path}
                      onClick={() => {
                        if (window.innerWidth < 600) {
                          setIsMobileOpen(false);
                        }
                      }}
                      sx={{
                        pl: 4,
                        borderRadius: 1,
                        mb: 0.5,
                        mx: 1,
                        '&.Mui-selected': {
                          backgroundColor: 'action.selected',
                          '& .MuiTypography-root': {
                            color: 'primary.main',
                            fontWeight: 500,
                          },
                        },
                      }}
                    >
                      <ListItemIcon>
                        {subItem.icon}
                      </ListItemIcon>
                      <ListItemText 
                        primary={subItem.title}
                        primaryTypographyProps={{
                          variant: 'body2'
                        }}
                      />
                    </SafeListItemButton>
                  ))}
                </List>
              </Collapse>
            )}
          </React.Fragment>
        ))}
      </List>

      <Box sx={{ flexGrow: 1 }} />

      <Box 
        sx={{ 
          p: 2, 
          borderTop: 1, 
          borderColor: 'divider',
          mt: 'auto'
        }}
      >
        <SafeListItemButton
          button
          onClick={handleLogout}
          sx={{
            '&:hover': {
              bgcolor: 'error.light',
              '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: 'error.contrastText',
              },
            },
          }}
        >
          <ListItemIcon sx={{ color: 'error.main' }}>
            <FaSignOutAlt size={20} />
          </ListItemIcon>
          <ListItemText 
            primary="Logout"
            sx={{
              opacity: sidebarIsCollapsed ? 0 : 1,
              transition: theme.transitions.create(['opacity'], {
                duration: theme.transitions.duration.shorter,
              }),
              '& .MuiTypography-root': {
                color: 'error.main',
              }
            }}
          />
        </SafeListItemButton>
      </Box>
    </Box>
  );

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="toggle mobile drawer"
        onClick={toggleMobileDrawer}
        edge="start"
        sx={{
          position: 'fixed',
          top: 12,
          left: 12,
          zIndex: (theme) => theme.zIndex.drawer + 3,
          display: { sm: 'none' },
          backgroundColor: (theme) => theme.palette.background.paper,
          boxShadow: (theme) => theme.shadows[2],
          '&:hover': {
            backgroundColor: (theme) => theme.palette.action.hover,
          },
        }}
      >
        {isMobileOpen ? <FaTimes /> : <FaBars />}
      </IconButton>
      <StyledSidebar
        key={`sidebar-${sidebarIsCollapsed}-${isMobileOpen}`}
        ownerState={{ isCollapsed: sidebarIsCollapsed, isMobileOpen }}
        className="custom-sidebar fade-in"
      >
        {drawer}
      </StyledSidebar>
    </>
  );
};

export default Sidebar;
