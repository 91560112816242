import React, { Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { ThemeProvider } from './contexts/ThemeContext';
import { SidebarProvider } from './contexts/SidebarContext';
import { NotificationProvider } from './contexts/NotificationContext';
import { CssBaseline, CircularProgress } from '@mui/material';
import { PrivateRoute } from './components/PrivateRoute';

// Lazy load components
const LoginPage = React.lazy(() => import('./LoginPage'));
const Dashboard = React.lazy(() => import('./components/Dashboard'));
const Properties = React.lazy(() => import('./components/Properties'));
const PropertyDetails = React.lazy(() => import('./components/PropertyDetails'));
const AddProperty = React.lazy(() => import('./components/AddProperty'));
const EditProperty = React.lazy(() => import('./components/EditProperty'));
const Tenants = React.lazy(() => import('./components/Tenants'));
const AddTenant = React.lazy(() => import('./components/AddTenant'));
const TenantDetails = React.lazy(() => import('./components/TenantDetails'));
const EditTenant = React.lazy(() => import('./components/EditTenant'));
const Maintenance = React.lazy(() => import('./components/Maintenance'));
const MaintenanceDetails = React.lazy(() => import('./components/MaintenanceDetails'));
const Finance = React.lazy(() => import('./components/Finance'));
const Reports = React.lazy(() => import('./components/Reports'));
const Landlords = React.lazy(() => import('./components/Landlords'));
const AddLandlord = React.lazy(() => import('./components/AddLandlord'));
const EditLandlord = React.lazy(() => import('./components/EditLandlord'));
const LandlordDetails = React.lazy(() => import('./components/LandlordDetails'));
const LandlordProperties = React.lazy(() => import('./components/LandlordProperties'));
const Profile = React.lazy(() => import('./components/Profile'));
const Settings = React.lazy(() => import('./components/Settings'));
const Messages = React.lazy(() => import('./components/Messages'));
const Notifications = React.lazy(() => import('./components/Notifications'));

// Loading component
const LoadingFallback = () => (
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <CircularProgress />
  </div>
);

function App() {
  return (
    <ThemeProvider>
      <CssBaseline />
      <AuthProvider>
        <NotificationProvider>
          <SidebarProvider>
            <Suspense fallback={<LoadingFallback />}>
              <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/" element={<Navigate to="/dashboard" replace />} />
                
                {/* Protected Routes */}
                <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route path="/properties" element={<PrivateRoute><Properties /></PrivateRoute>} />
                <Route path="/properties/:id" element={<PrivateRoute><PropertyDetails /></PrivateRoute>} />
                <Route path="/properties/add" element={<PrivateRoute><AddProperty /></PrivateRoute>} />
                <Route path="/properties/edit/:id" element={<PrivateRoute><EditProperty /></PrivateRoute>} />
                
                <Route path="/tenants" element={<PrivateRoute><Tenants /></PrivateRoute>} />
                <Route path="/tenants/add" element={<PrivateRoute><AddTenant /></PrivateRoute>} />
                <Route path="/tenants/:id" element={<PrivateRoute><TenantDetails /></PrivateRoute>} />
                <Route path="/tenants/edit/:id" element={<PrivateRoute><EditTenant /></PrivateRoute>} />
                <Route path="/messages" element={<PrivateRoute><Messages /></PrivateRoute>} />
                <Route path="/notifications" element={<PrivateRoute><Notifications /></PrivateRoute>} />
                
                <Route path="/maintenance" element={<PrivateRoute><Maintenance /></PrivateRoute>} />
                <Route path="/maintenance/:id" element={<PrivateRoute><MaintenanceDetails /></PrivateRoute>} />
                
                <Route path="/finance" element={<PrivateRoute><Finance /></PrivateRoute>} />
                <Route path="/reports" element={<PrivateRoute><Reports /></PrivateRoute>} />
                
                <Route path="/landlords" element={<PrivateRoute><Landlords /></PrivateRoute>} />
                <Route path="/landlords/add" element={<PrivateRoute><AddLandlord /></PrivateRoute>} />
                <Route path="/landlords/:id" element={<PrivateRoute><LandlordDetails /></PrivateRoute>} />
                <Route path="/landlords/edit/:id" element={<PrivateRoute><EditLandlord /></PrivateRoute>} />
                <Route path="/landlords/:id/properties" element={<PrivateRoute><LandlordProperties /></PrivateRoute>} />
                
                <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
                <Route 
                  path="/settings" 
                  element={
                    <PrivateRoute excludeRoles={['landlord']}>
                      <Settings />
                    </PrivateRoute>
                  } 
                />
                
                {/* Catch all route */}
                <Route path="*" element={<Navigate to="/dashboard" replace />} />
              </Routes>
            </Suspense>
          </SidebarProvider>
        </NotificationProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
