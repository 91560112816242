import { db } from '../firebaseConfig';
import { collection, query, getDocs } from 'firebase/firestore';

export const searchAcrossCollections = async (searchQuery, userRole) => {
  console.group('Search Across Collections');
  console.log('Search Query:', searchQuery);
  console.log('User Role:', userRole);

  // Validate input
  if (!searchQuery || typeof searchQuery !== 'string') {
    console.warn('Invalid search query');
    console.groupEnd();
    return [];
  }

  const searchTerm = searchQuery.toLowerCase().trim();
  const results = [];

  try {
    // Search in Properties
    console.log('Searching Properties...');
    const propertiesRef = collection(db, 'properties');
    const propertyDocs = await getDocs(propertiesRef);
    propertyDocs.forEach(doc => {
      const data = doc.data();
      const matchConditions = [
        data.address?.toLowerCase().includes(searchTerm),
        data.propertyName?.toLowerCase().includes(searchTerm)
      ];

      if (matchConditions.some(Boolean)) {
        results.push({
          id: doc.id,
          type: 'property',
          title: data.propertyName || data.address,
          subtitle: data.propertyName ? data.address : '',
          description: data.description || '',
          data: doc.data(),
          path: `/properties/${doc.id}`
        });
      }
    });

    // Only search in Tenants if user is NOT a Landlord
    if (userRole !== 'Landlord') {
      console.log('Searching Tenants...');
      const tenantsRef = collection(db, 'tenants');
      const tenantDocs = await getDocs(tenantsRef);
      tenantDocs.forEach(doc => {
        const data = doc.data();
        const matchConditions = [
          data.firstName?.toLowerCase().includes(searchTerm),
          data.lastName?.toLowerCase().includes(searchTerm),
          data.email?.toLowerCase().includes(searchTerm)
        ];

        if (matchConditions.some(Boolean)) {
          results.push({
            id: doc.id,
            type: 'tenant',
            title: `${data.firstName} ${data.lastName}`,
            subtitle: data.email,
            description: data.phone || '',
            data: data,
            path: `/tenants/${doc.id}`
          });
        }
      });
    } else {
      console.log('Skipping tenant search for Landlord user');
    }

    // Search in Maintenance
    console.log('Searching Maintenance...');
    const maintenanceRef = collection(db, 'maintenance');
    const maintenanceDocs = await getDocs(maintenanceRef);
    maintenanceDocs.forEach(doc => {
      const data = doc.data();
      const matchConditions = [
        data.title?.toLowerCase().includes(searchTerm),
        data.description?.toLowerCase().includes(searchTerm)
      ];

      if (matchConditions.some(Boolean)) {
        results.push({
          id: doc.id,
          type: 'maintenance',
          title: data.title,
          subtitle: data.description,
          description: `Status: ${data.status}`,
          data: data,
          path: `/maintenance/${doc.id}`
        });
      }
    });

    console.log('Total Search Results:', results.length);
    console.groupEnd();
    return results;
  } catch (error) {
    console.error('Error searching across collections:', error);
    console.groupEnd();
    throw error;
  }
};
