import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { AuthProvider } from './contexts/AuthContext';
import { BrowserRouter, createBrowserRouter, RouterProvider } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, initializationPromise } from './firebaseConfig';

// Create router with future flags enabled
const router = createBrowserRouter([
  {
    path: '*',
    element: (
      <AuthProvider>
        <App />
      </AuthProvider>
    ),
  }
], {
  basename: process.env.PUBLIC_URL,
  future: {
    v7_startTransition: true,
    v7_relativeSplatPath: true
  }
});

// Wait for Firebase Auth to initialize before rendering
const renderApp = () => {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
};

// Check if auth is initialized
const waitForAuth = async () => {
  // First wait for Firebase to initialize
  await initializationPromise;
  
  // Then wait for auth state to be ready
  return new Promise((resolve) => {
    const unsubscribe = onAuthStateChanged(auth, () => {
      unsubscribe();
      resolve();
    });
  });
};

// Initialize the app
waitForAuth()
  .then(() => {
    console.log('Firebase Auth initialized');
    renderApp();
  })
  .catch((error) => {
    console.error('Error initializing Firebase Auth:', error);
    // Render app anyway to show error state
    renderApp();
  });
