import { 
  initFirebase,
  getFirebaseAuth,
  getFirebaseDb,
  getFirebaseStorage,
  getFirebaseAnalytics
} from './config/firebase';

// Initialize Firebase and export services
let auth = null;
let db = null;
let storage = null;
let analytics = null;
let isInitialized = false;

const initializeServices = async () => {
  if (!isInitialized) {
    await initFirebase();
    auth = getFirebaseAuth();
    db = getFirebaseDb();
    storage = getFirebaseStorage();
    analytics = getFirebaseAnalytics();
    isInitialized = true;
  }
  return { auth, db, storage, analytics };
};

// Initialize services immediately
const initializationPromise = initializeServices().catch(console.error);

// Export the services and initialization promise
export { auth, db, storage, analytics, initializeServices, initializationPromise };
