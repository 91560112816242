import { initializeApp } from 'firebase/app';
import { 
  getAuth, 
  browserLocalPersistence, 
  browserSessionPersistence, 
  setPersistence 
} from 'firebase/auth';
import { 
  getFirestore,
  initializeFirestore,
  CACHE_SIZE_UNLIMITED 
} from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAnalytics, isSupported } from 'firebase/analytics';

class FirebaseConfig {
  constructor() {
    this.initialized = false;
    this.app = null;
    this.auth = null;
    this.db = null;
    this.storage = null;
    this.analytics = null;
  }

  getConfig() {
    return {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
    };
  }

  async initializeApp() {
    if (this.initialized) {
      return;
    }

    try {
      // Initialize Firebase app
      const config = this.getConfig();
      this.validateConfig(config);
      this.app = initializeApp(config);
      
      // Initialize services
      await this.initializeServices();
      
      console.log('Firebase initialized successfully');
    } catch (error) {
      console.error('Error initializing Firebase:', error);
      throw new Error('Failed to initialize Firebase');
    }
  }

  validateConfig(config) {
    const requiredFields = [
      'apiKey',
      'authDomain',
      'projectId',
      'storageBucket',
      'messagingSenderId',
      'appId'
    ];

    const missingFields = requiredFields.filter(field => !config[field]);
    if (missingFields.length > 0) {
      throw new Error(`Missing required Firebase configuration fields: ${missingFields.join(', ')}`);
    }
  }

  async initializeServices() {
    try {
      // Initialize Firestore with persistence settings
      this.db = initializeFirestore(this.app, {
        cache: {
          sizeBytes: CACHE_SIZE_UNLIMITED
        }
      });

      this.auth = getAuth(this.app);
      this.storage = getStorage(this.app);
      
      await this.initializeAuthPersistence();
      if (process.env.REACT_APP_ENABLE_ANALYTICS === 'true') {
        await this.initializeAnalytics();
      }
      
      this.initialized = true;
      console.log('Firebase services initialized successfully');
    } catch (error) {
      console.error('Error initializing Firebase services:', error);
      throw error;
    }
  }

  async initializeAuthPersistence() {
    try {
      const persistenceMode = 
        process.env.REACT_APP_ENV === 'production' 
          ? browserLocalPersistence 
          : browserSessionPersistence;
      
      await setPersistence(this.auth, persistenceMode);
      console.log('Auth persistence initialized');
    } catch (error) {
      console.error('Error initializing auth persistence:', error);
      // Fall back to default persistence
    }
  }

  async initializeAnalytics() {
    try {
      const analyticsSupported = await isSupported();
      if (analyticsSupported) {
        this.analytics = getAnalytics(this.app);
        console.log('Analytics initialized');
      } else {
        console.log('Analytics not supported in this environment');
      }
    } catch (error) {
      console.error('Error initializing analytics:', error);
    }
  }

  // Getter methods
  getApp() {
    return this.app;
  }

  getAuth() {
    return this.auth;
  }

  getDb() {
    return this.db;
  }

  getStorage() {
    return this.storage;
  }

  getAnalytics() {
    return this.analytics;
  }
}

// Create and initialize a single instance
const firebaseInstance = new FirebaseConfig();

// Export initialized services
export const initFirebase = () => firebaseInstance.initializeApp();
export const getFirebaseApp = () => firebaseInstance.getApp();
export const getFirebaseAuth = () => firebaseInstance.getAuth();
export const getFirebaseDb = () => firebaseInstance.getDb();
export const getFirebaseStorage = () => firebaseInstance.getStorage();
export const getFirebaseAnalytics = () => firebaseInstance.getAnalytics();
