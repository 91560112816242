import { createContext, useContext, useState, useEffect } from 'react';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { lightTheme, darkTheme } from '../theme';
import { doc, getDoc, setDoc, updateDoc, onSnapshot } from 'firebase/firestore';
import { getFirebaseDb } from '../config/firebase';
import { useAuth } from './AuthContext';

const ThemeContext = createContext({
  isDarkMode: false,
  toggleTheme: () => {},
  primaryColor: '',
  secondaryColor: '',
  setPrimaryColor: () => {},
  setSecondaryColor: () => {},
  theme: null,
  isAdmin: false,
});

export const ThemeProvider = ({ children }) => {
  const { currentUser, userRole } = useAuth();
  const [isAdmin, setIsAdmin] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [primaryColor, setPrimaryColor] = useState(lightTheme.palette.primary.main);
  const [secondaryColor, setSecondaryColor] = useState(lightTheme.palette.secondary.main);
  const [isInitialized, setIsInitialized] = useState(false);
  const [globalTheme, setGlobalTheme] = useState(null);

  // Check if user is admin
  useEffect(() => {
    if (userRole === 'admin' || userRole === 'administrator') {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [userRole]);

  // Load user preferences
  useEffect(() => {
    const loadUserPreferences = async () => {
      if (!currentUser) return;
      
      try {
        const db = getFirebaseDb();
        const userPrefsRef = doc(db, 'userPreferences', currentUser.uid);
        const userPrefsDoc = await getDoc(userPrefsRef);
        
        if (userPrefsDoc.exists()) {
          const prefs = userPrefsDoc.data();
          if (prefs?.theme) {
            setIsDarkMode(prefs.theme.isDarkMode ?? false);
          }
        }
      } catch (error) {
        console.error('Error loading user preferences:', error);
      }
    };

    loadUserPreferences();
  }, [currentUser]);

  // Subscribe to global settings
  useEffect(() => {
    let unsubscribe;

    const initializeTheme = async () => {
      try {
        const db = getFirebaseDb();
        const settingsRef = doc(db, 'settings', 'appSettings');
        const settingsDoc = await getDoc(settingsRef);
        
        if (settingsDoc.exists()) {
          const settings = settingsDoc.data();
          if (settings?.theme) {
            const { primaryColor: themePrimary, secondaryColor: themeSecondary } = settings.theme;
            setPrimaryColor(themePrimary || lightTheme.palette.primary.main);
            setSecondaryColor(themeSecondary || lightTheme.palette.secondary.main);
            setGlobalTheme(settings.theme);
          }
        } else if (isAdmin) {
          const defaultSettings = {
            theme: {
              primaryColor: lightTheme.palette.primary.main,
              secondaryColor: lightTheme.palette.secondary.main,
            },
            updatedAt: new Date().toISOString(),
            updatedBy: currentUser?.uid
          };
          await setDoc(settingsRef, defaultSettings);
          setGlobalTheme(defaultSettings.theme);
        }

        unsubscribe = onSnapshot(settingsRef, (doc) => {
          if (doc.exists()) {
            const settings = doc.data();
            if (settings?.theme) {
              const { primaryColor: themePrimary, secondaryColor: themeSecondary } = settings.theme;
              setPrimaryColor(themePrimary || lightTheme.palette.primary.main);
              setSecondaryColor(themeSecondary || lightTheme.palette.secondary.main);
              setGlobalTheme(settings.theme);
            }
          }
        }, (error) => {
          console.error('Error subscribing to theme settings:', error);
        });

        setIsInitialized(true);
      } catch (error) {
        console.error('Error initializing theme:', error);
        setIsInitialized(true);
      }
    };

    if (currentUser) {
      initializeTheme();
    } else {
      setIsDarkMode(false);
      setPrimaryColor(lightTheme.palette.primary.main);
      setSecondaryColor(lightTheme.palette.secondary.main);
      setGlobalTheme(null);
      setIsInitialized(true);
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [currentUser, isAdmin]);

  const saveUserPreferences = async (isDarkMode) => {
    if (!currentUser) return;

    try {
      const db = getFirebaseDb();
      const userPrefsRef = doc(db, 'userPreferences', currentUser.uid);
      await setDoc(userPrefsRef, {
        theme: {
          isDarkMode,
          updatedAt: new Date().toISOString()
        }
      }, { merge: true });
    } catch (error) {
      console.error('Error saving user preferences:', error);
      throw error;
    }
  };

  const saveThemeSettings = async (updates) => {
    if (!isAdmin) return;

    try {
      const db = getFirebaseDb();
      const settingsRef = doc(db, 'settings', 'appSettings');
      const settingsDoc = await getDoc(settingsRef);
      
      const newSettings = {
        theme: {
          ...globalTheme,
          primaryColor,
          secondaryColor,
          ...updates
        },
        updatedAt: new Date().toISOString(),
        updatedBy: currentUser?.uid
      };

      if (settingsDoc.exists()) {
        await updateDoc(settingsRef, newSettings);
      } else {
        await setDoc(settingsRef, newSettings);
      }
    } catch (error) {
      console.error('Error saving theme settings:', error);
      throw error;
    }
  };

  const theme = createTheme(
    isDarkMode
      ? {
          ...darkTheme,
          palette: {
            ...darkTheme.palette,
            primary: {
              ...darkTheme.palette.primary,
              main: primaryColor,
            },
            secondary: {
              ...darkTheme.palette.secondary,
              main: secondaryColor,
            },
          },
        }
      : {
          ...lightTheme,
          palette: {
            ...lightTheme.palette,
            primary: {
              ...lightTheme.palette.primary,
              main: primaryColor,
            },
            secondary: {
              ...lightTheme.palette.secondary,
              main: secondaryColor,
            },
          },
        }
  );

  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty('--primary-main', theme.palette.primary.main);
    root.style.setProperty('--primary-light', theme.palette.primary.light);
    root.style.setProperty('--background-paper', theme.palette.background.paper);
  }, [theme]);

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
    if (currentUser) {
      const db = getFirebaseDb();
      const userPrefsRef = doc(db, 'userPreferences', currentUser.uid);
      updateDoc(userPrefsRef, {
        'theme.isDarkMode': !isDarkMode,
        updatedAt: new Date().toISOString(),
      }).catch(console.error);
    }
  };

  const handleSetPrimaryColor = async (color) => {
    if (!isAdmin) return;
    
    setPrimaryColor(color);
    try {
      await saveThemeSettings({ primaryColor: color });
    } catch (error) {
      setPrimaryColor(primaryColor); // Revert on error
    }
  };

  const handleSetSecondaryColor = async (color) => {
    if (!isAdmin) return;
    
    setSecondaryColor(color);
    try {
      await saveThemeSettings({ secondaryColor: color });
    } catch (error) {
      setSecondaryColor(secondaryColor); // Revert on error
    }
  };

  if (!isInitialized) {
    return null;
  }

  return (
    <ThemeContext.Provider
      value={{
        isDarkMode,
        toggleTheme,
        primaryColor,
        secondaryColor,
        setPrimaryColor: handleSetPrimaryColor,
        setSecondaryColor: handleSetSecondaryColor,
        theme,
        isAdmin,
      }}
    >
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};
