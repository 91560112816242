import { 
  createUserWithEmailAndPassword, 
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  getAuth
} from 'firebase/auth';
import { 
  collection, 
  doc, 
  getDocs, 
  getDoc, 
  setDoc, 
  query, 
  where,
  serverTimestamp,
  updateDoc,
  writeBatch
} from 'firebase/firestore';
import { auth, db } from '../firebaseConfig';

const USERS_COLLECTION = 'users';
const PROPERTIES_COLLECTION = 'properties';

// Get current user role
export const getCurrentUserRole = async () => {
  try {
    const userId = auth.currentUser?.uid;
    if (!userId) {
      return null;
    }

    const userDoc = await getDoc(doc(db, USERS_COLLECTION, userId));
    if (!userDoc.exists()) {
      return null;
    }

    const userData = userDoc.data();
    return userData.role;
  } catch (error) {
    return null;
  }
};

// Get user role by ID
export const getUserRole = async (userId) => {
  try {
    if (!userId) {
      console.log('getUserRole: No userId provided');
      return null;
    }

    const userDoc = await getDoc(doc(db, USERS_COLLECTION, userId));
    if (!userDoc.exists()) {
      console.log('getUserRole: User document not found for ID:', userId);
      return null;
    }
    
    const userData = userDoc.data();
    console.log('getUserRole: User role:', userData.role);
    return userData.role;
  } catch (error) {
    console.error('Error in getUserRole:', error);
    return null;
  }
};

// Create a new user with role
export const createUser = async (userData, role, properties = []) => {
  const secondaryAuth = getAuth(); // Create a secondary auth instance
  try {
    // Store current user's data
    const currentUser = auth.currentUser;
    if (!currentUser) {
      throw new Error('No authenticated user found');
    }
    
    // Get current user's document to verify role
    const currentUserDoc = await getDoc(doc(db, USERS_COLLECTION, currentUser.uid));
    const currentUserRole = currentUserDoc.data()?.role;
    
    if (!['admin', 'agent', 'administrator'].includes(currentUserRole)) {
      throw new Error(`Unauthorized. Your role (${currentUserRole}) cannot create new users.`);
    }

    console.log('createUser: Creating new user with email:', userData.email);
    
    // Create user document with role and profile data
    const userDoc = {
      email: userData.email,
      firstName: userData.firstName,
      lastName: userData.lastName,
      phone: userData.phone,
      role: role,
      address: userData.address,
      city: userData.city,
      postcode: userData.postcode,
      status: 'pending', // pending until first login
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      createdBy: currentUser.uid
    };

    // Add bank details if role is landlord
    if (role === 'landlord') {
      userDoc.bankName = userData.bankName;
      userDoc.accountNumber = userData.accountNumber;
      userDoc.sortCode = userData.sortCode;
    }

    // Create auth user
    const userCredential = await createUserWithEmailAndPassword(
      secondaryAuth,
      userData.email,
      userData.password
    );
    
    userDoc.uid = userCredential.user.uid;
    
    console.log('createUser: Creating user document with data:', userDoc);
    // Create user document
    await setDoc(doc(db, USERS_COLLECTION, userCredential.user.uid), userDoc);

    // Link properties to the landlord if any are provided
    if (role === 'landlord' && properties.length > 0) {
      console.log('createUser: Linking properties to landlord:', properties);
      const batch = writeBatch(db);
      
      // First verify all properties exist and are available
      const propertyRefs = properties.map(id => doc(db, PROPERTIES_COLLECTION, id));
      const propertyDocs = await Promise.all(propertyRefs.map(ref => getDoc(ref)));
      
      for (let i = 0; i < propertyDocs.length; i++) {
        const propertyDoc = propertyDocs[i];
        if (!propertyDoc.exists()) {
          throw new Error(`Property ${properties[i]} not found`);
        }
        const propertyData = propertyDoc.data();
        if (propertyData.landlordId) {
          throw new Error(`Property ${properties[i]} is already assigned to a landlord`);
        }
        
        batch.update(propertyRefs[i], { 
          landlordId: userCredential.user.uid,
          landlordName: `${userData.firstName} ${userData.lastName}`,
          landlordEmail: userData.email,
          landlordPhone: userData.phone,
          updatedAt: serverTimestamp()
        });
      }
      
      await batch.commit();
    }

    console.log('createUser: Sending password reset email to:', userData.email);
    // Send password reset email
    await sendPasswordResetEmail(secondaryAuth, userData.email);
    
    // Sign out from the secondary auth instance
    await signOut(secondaryAuth);

    return {
      ...userDoc,
      id: userCredential.user.uid,
      properties: properties
    };
  } catch (error) {
    console.error('Error in createUser:', error);
    // Make sure to sign out of the secondary auth instance if there's an error
    try {
      await signOut(secondaryAuth);
    } catch (signOutError) {
      console.error('Error signing out of secondary auth:', signOutError);
    }
    throw error;
  }
};

// Get users by role
export const getUsersByRole = async (role) => {
  try {
    const userId = auth.currentUser?.uid;
    if (!userId) {
      console.error('getUsersByRole: No user authenticated');
      throw new Error('User not authenticated');
    }

    // Get current user's role
    const currentUserRole = await getCurrentUserRole();
    console.log('getUsersByRole: Current user role:', currentUserRole);
    
    if (!currentUserRole) {
      console.error('getUsersByRole: User role not found');
      throw new Error('User role not found');
    }

    // Check user authorization
    const authorizedRoles = ['admin', 'agent', 'administrator'];
    if (!authorizedRoles.includes(currentUserRole)) {
      console.error('getUsersByRole: Unauthorized access attempt with role:', currentUserRole);
      throw new Error('Unauthorized to view user list');
    }

    console.log('getUsersByRole: Fetching users with role:', role);
    const q = query(
      collection(db, USERS_COLLECTION), 
      where("role", "==", role)
    );
    const querySnapshot = await getDocs(q);
    const users = querySnapshot.docs.map(doc => {
      const data = doc.data();
      console.log('User data:', data);
      return {
        id: doc.id,
        ...data
      };
    });
    console.log('getUsersByRole: Found users:', users.length);
    return users;
  } catch (error) {
    console.error('Error in getUsersByRole:', error);
    throw error;
  }
};

// Get user properties
export const getUserProperties = async (userId) => {
  try {
    console.log('getUserProperties: Fetching properties for user:', userId);
    const q = query(
      collection(db, PROPERTIES_COLLECTION),
      where("landlordId", "==", userId)
    );
    const querySnapshot = await getDocs(q);
    const properties = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    console.log('getUserProperties: Found properties:', properties.length);
    return properties;
  } catch (error) {
    console.error('Error getting user properties:', error);
    throw error;
  }
};

// Get user by ID
export const getUserById = async (userId) => {
  try {
    const currentUserId = auth.currentUser?.uid;
    if (!currentUserId) {
      console.error('getUserById: No user authenticated');
      throw new Error('User not authenticated');
    }

    console.log('getUserById: Fetching user with ID:', userId);
    const docRef = doc(db, USERS_COLLECTION, userId);
    const docSnap = await getDoc(docRef);
    
    if (!docSnap.exists()) {
      console.log('getUserById: User not found with ID:', userId);
      throw new Error('User not found');
    }

    console.log('getUserById: Found user with ID:', userId);
    return {
      id: docSnap.id,
      ...docSnap.data()
    };
  } catch (error) {
    console.error('Error getting user:', error);
    throw error;
  }
};

export const setUserRole = async (role) => {
  try {
    const user = auth.currentUser;
    if (!user) throw new Error('No user is currently logged in');

    const userRef = doc(db, USERS_COLLECTION, user.uid);
    const userDoc = await getDoc(userRef);
    
    if (!userDoc.exists()) {
      // Create new user document if it doesn't exist
      await setDoc(userRef, {
        email: user.email,
        role: role,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      });
      console.log('setUserRole: Created new user document with role:', role);
    } else {
      // Update existing user document
      await updateDoc(userRef, {
        role: role,
        updatedAt: serverTimestamp()
      });
      console.log('setUserRole: Updated user role to:', role);
    }
    
    return true;
  } catch (error) {
    console.error('Error setting user role:', error);
    throw error;
  }
};

export const createUserIfNotExists = async () => {
  try {
    const user = auth.currentUser;
    if (!user) {
      console.error('No user is currently logged in');
      return null;
    }

    const userRef = doc(db, 'users', user.uid);
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) {
      const userData = {
        email: user.email,
        role: 'administrator',
        createdAt: serverTimestamp(),
      };
      await setDoc(userRef, userData);
      console.log('Created new user with administrator role:', userData);
      return userData;
    } else {
      const existingData = userDoc.data();
      if (!existingData.role) {
        const updatedData = {
          ...existingData,
          role: 'administrator',
          updatedAt: serverTimestamp(),
        };
        await setDoc(userRef, updatedData, { merge: true });
        console.log('Updated existing user with administrator role:', updatedData);
        return updatedData;
      }
      console.log('User already exists with role:', existingData.role);
      return existingData;
    }
  } catch (error) {
    console.error('Error in createUserIfNotExists:', error);
    throw error;
  }
};

// Removed seedDummyLandlords function
export const seedDummyLandlords = undefined;
