import React, { useState, useEffect, useRef, useCallback } from 'react';
import { 
  IconButton, 
  Menu, 
  MenuItem, 
  Avatar, 
  Tooltip, 
  Badge,
  AppBar as MuiAppBar,
  Toolbar,
  Box,
  InputBase,
  useTheme as useMuiTheme,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
  Popper,
  Grow,
  ClickAwayListener,
  Button,
  Divider,
  CircularProgress
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { 
  FaSearch, 
  FaBell, 
  FaCog, 
  FaUserCircle, 
  FaSignOutAlt,
  FaSun,
  FaMoon,
  FaBars,
  FaHome,
  FaUser,
  FaTools,
  FaChevronDown,
  FaCommentAlt
} from 'react-icons/fa';
import { db } from '../firebaseConfig';
import { 
  collection, 
  query, 
  where, 
  getDocs, 
  onSnapshot, 
  doc, 
  getDoc
} from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { useTheme as useAppTheme } from '../contexts/ThemeContext';
import { useSidebar } from '../contexts/SidebarContext';
import { useAuth } from '../contexts/AuthContext';
import { searchAcrossCollections } from '../utils/searchUtils';
import debounce from 'lodash/debounce';

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  boxShadow: 'none',
  borderBottom: `1px solid ${theme.palette.divider}`,
  zIndex: theme.zIndex.drawer + 1,
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.black, 0.05),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.black, 0.08),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.text.secondary,
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: theme.palette.text.primary,
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '40ch',
    },
  },
}));

const SearchResultsPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  top: 'calc(100% + 8px)',
  left: 0,
  right: 0,
  maxHeight: '400px',
  overflow: 'auto',
  zIndex: 1400,
  borderRadius: theme.shape.borderRadius * 2,
  border: `1px solid ${theme.palette.divider}`,
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.08)',
  backgroundColor: theme.palette.mode === 'dark' 
    ? alpha(theme.palette.background.paper, 0.95)
    : alpha(theme.palette.background.paper, 0.95),
  backdropFilter: 'blur(8px)',
}));

const UserButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 3,
  padding: theme.spacing(0.75, 2),
  backgroundColor: theme.palette.mode === 'dark'
    ? alpha(theme.palette.common.white, 0.04)
    : alpha(theme.palette.common.black, 0.02),
  color: theme.palette.text.primary,
  transition: theme.transitions.create(['background-color', 'box-shadow'], {
    duration: theme.transitions.duration.shorter,
  }),
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark'
      ? alpha(theme.palette.common.white, 0.08)
      : alpha(theme.palette.common.black, 0.04),
    boxShadow: `0 0 0 1px ${alpha(theme.palette.divider, 0.1)}`,
  },
}));

const TopBar = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [notificationCount, setNotificationCount] = useState({ system: 0, messages: 0 });
  const { currentUser, logout, userRole } = useAuth();
  const theme = useMuiTheme();
  const { isCollapsed, setIsCollapsed } = useSidebar();
  const { isDarkMode, toggleTheme } = useAppTheme();
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [logoutLoading, setLogoutLoading] = useState(false);
  const [selectedResultIndex, setSelectedResultIndex] = useState(-1);
  const searchInputRef = useRef(null);
  const [userFullName, setUserFullName] = useState('');

  useEffect(() => {
    console.group('TopBar Component Initialization');
    console.log('Current User:', currentUser);
    console.log('Is Dark Mode:', isDarkMode);
    console.log('Sidebar Collapsed:', isCollapsed);
    console.groupEnd();
  }, [currentUser, isDarkMode, isCollapsed]);

  useEffect(() => {
    if (!currentUser) {
      console.warn('No current user - redirecting to login');
      navigate('/login');
      return;
    }

    // Listen for both system notifications and unread messages
    const notificationsRef = collection(db, 'notifications');
    const notificationsQuery = query(
      notificationsRef,
      where('userId', '==', currentUser.uid),
      where('read', '==', false)
    );

    const conversationsRef = collection(db, 'conversations');
    const conversationsQuery = query(
      conversationsRef,
      where('participants', 'array-contains', currentUser.uid)
    );

    // Combine notifications from both sources
    const unsubscribeNotifications = onSnapshot(notificationsQuery, (snapshot) => {
      const systemNotifications = snapshot.size;
      setNotificationCount(prev => ({
        ...prev,
        system: systemNotifications
      }));
    });

    const unsubscribeConversations = onSnapshot(conversationsQuery, (snapshot) => {
      let unreadMessages = 0;
      snapshot.docs.forEach(doc => {
        const conv = doc.data();
        if (conv.lastMessageTime && (!conv.lastRead?.[currentUser.uid] || 
            conv.lastMessageTime.seconds > conv.lastRead[currentUser.uid].seconds) && 
            conv.lastMessage && 
            conv.lastMessageSenderId !== currentUser.uid) {
          unreadMessages++;
        }
      });
      setNotificationCount(prev => ({
        ...prev,
        messages: unreadMessages
      }));
    });

    return () => {
      unsubscribeNotifications();
      unsubscribeConversations();
    };
  }, [currentUser, navigate]);

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser?.uid) {
        try {
          const userRef = doc(db, 'users', currentUser.uid);
          const userSnap = await getDoc(userRef);
          if (userSnap.exists()) {
            const userData = userSnap.data();
            const fullName = `${userData.firstName || ''} ${userData.lastName || ''}`.trim();
            setUserFullName(fullName || currentUser.email);
          } else {
            setUserFullName(currentUser.email);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          setUserFullName(currentUser.email);
        }
      }
    };

    fetchUserData();
  }, [currentUser]);

  const debouncedSearch = useCallback(
    debounce(async (query) => {
      if (!query || query.trim().length < 2) {
        setSearchResults([]);
        return;
      }

      try {
        setIsSearching(true);
        console.log('Current user role:', userRole);
        const results = await searchAcrossCollections(query, userRole);
        setSearchResults(results);
        setSelectedResultIndex(-1);
      } catch (error) {
        console.error('Search error:', error);
      } finally {
        setIsSearching(false);
      }
    }, 300),
    [userRole]
  );

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    debouncedSearch(query);
  };

  const handleSearchKeyDown = (event) => {
    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();
        setSelectedResultIndex((prev) => 
          prev < searchResults.length - 1 ? prev + 1 : prev
        );
        break;
      
      case 'ArrowUp':
        event.preventDefault();
        setSelectedResultIndex((prev) => 
          prev > 0 ? prev - 1 : -1
        );
        break;
      
      case 'Enter':
        event.preventDefault();
        if (selectedResultIndex >= 0 && searchResults[selectedResultIndex]) {
          handleSearchResultClick(searchResults[selectedResultIndex]);
        } else if (searchResults.length > 0) {
          handleSearchResultClick(searchResults[0]);
        }
        break;
      
      case 'Escape':
        setSearchResults([]);
        setSelectedResultIndex(-1);
        break;
    }
  };

  const handleSearchResultClick = (result) => {
    if (!result || !result.path) {
      console.warn('Invalid search result:', result);
      return;
    }

    try {
      navigate(result.path);
      
      // Reset search state
      setSearchQuery('');
      setSearchResults([]);
      setSelectedResultIndex(-1);
    } catch (error) {
      console.error('Navigation error:', error);
    }
  };

  const getSearchResultIcon = (type) => {
    switch (type) {
      case 'property': return <FaHome />;
      case 'tenant': return <FaUser />;
      case 'maintenance': return <FaTools />;
      default: return <FaSearch />;
    }
  };

  const handleMenu = (event) => {
    console.log('Menu opened', event.currentTarget);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    console.log('Menu closed');
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    console.group('Logout Process');
    console.log('Initiating logout');
    try {
      setLogoutLoading(true);
      await logout();
      console.log('Logout successful');
      navigate('/login');
    } catch (error) {
      console.error('Logout error:', error);
    } finally {
      setLogoutLoading(false);
      handleClose();
    }
    console.groupEnd();
  };

  const handleProfile = () => {
    console.log('Navigating to profile');
    navigate('/profile');
    handleClose();
  };

  const handleSettings = () => {
    console.log('Navigating to settings');
    navigate('/settings');
    handleClose();
  };

  const handleNotificationsClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    navigate('/notifications');
  };

  return (
    <AppBar position="fixed">
      <Toolbar>
        <IconButton
          color="inherit"
          edge="start"
          onClick={() => setIsCollapsed(!isCollapsed)}
          sx={{ mr: 2, display: { sm: 'none' } }}
        >
          <FaBars size={18} />
        </IconButton>

        {/* Search Component */}
        <Search>
          <SearchIconWrapper>
            {isSearching ? (
              <CircularProgress size={16} color="inherit" />
            ) : (
              <FaSearch />
            )}
          </SearchIconWrapper>
          
          <StyledInputBase
            ref={searchInputRef}
            placeholder={userRole === 'Landlord' 
              ? "Search properties, maintenance..." 
              : "Search properties, tenants, maintenance..."}
            value={searchQuery}
            onChange={handleSearchChange}
            onKeyDown={handleSearchKeyDown}
          />

          {/* Search Results Dropdown */}
          {searchResults.length > 0 && (
            <SearchResultsPaper>
              <List>
                {searchResults.map((result, index) => (
                  <ListItem
                    key={`${result.type}-${result.id}`}
                    button
                    selected={selectedResultIndex === index}
                    onClick={() => handleSearchResultClick(result)}
                    sx={{
                      '&.Mui-selected': {
                        backgroundColor: theme.palette.action.selected,
                      },
                    }}
                  >
                    <ListItemIcon>
                      {getSearchResultIcon(result.type)}
                    </ListItemIcon>
                    <ListItemText
                      primary={result.title}
                      secondary={result.subtitle || result.description}
                    />
                  </ListItem>
                ))}
              </List>
            </SearchResultsPaper>
          )}
        </Search>

        <Box sx={{ flexGrow: 1 }} />

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {/* Theme Toggle */}
          <IconButton
            onClick={toggleTheme}
            color="inherit"
            sx={{ 
              width: 40,
              height: 40,
              backgroundColor: isDarkMode
                ? alpha(theme.palette.common.white, 0.04)
                : alpha(theme.palette.common.black, 0.02),
              '&:hover': {
                backgroundColor: isDarkMode
                  ? alpha(theme.palette.common.white, 0.08)
                  : alpha(theme.palette.common.black, 0.04),
              }
            }}
          >
            {isDarkMode ? <FaSun size={16} /> : <FaMoon size={16} />}
          </IconButton>

          <Box sx={{ display: 'flex', gap: 1 }}>
            {notificationCount.messages > 0 && (
              <IconButton
                color="inherit"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  navigate('/messages');
                }}
                sx={{
                  width: 40,
                  height: 40,
                  backgroundColor: theme.palette.mode === 'dark'
                    ? alpha(theme.palette.common.white, 0.04)
                    : alpha(theme.palette.common.black, 0.02),
                  '&:hover': {
                    backgroundColor: theme.palette.mode === 'dark'
                      ? alpha(theme.palette.common.white, 0.08)
                      : alpha(theme.palette.common.black, 0.04),
                  },
                  position: 'relative',
                }}
              >
                <Badge 
                  badgeContent={notificationCount.messages} 
                  color="error"
                  sx={{
                    '& .MuiBadge-badge': {
                      backgroundColor: theme.palette.error.main,
                      color: theme.palette.error.contrastText,
                      fontWeight: 600,
                      minWidth: 20,
                      height: 20,
                      padding: '0 6px',
                      fontSize: '0.75rem',
                    },
                  }}
                >
                  <FaCommentAlt size={16} />
                </Badge>
              </IconButton>
            )}

            <IconButton
              color="inherit"
              onClick={handleNotificationsClick}
              sx={{
                width: 40,
                height: 40,
                backgroundColor: theme.palette.mode === 'dark'
                  ? alpha(theme.palette.common.white, 0.04)
                  : alpha(theme.palette.common.black, 0.02),
                '&:hover': {
                  backgroundColor: theme.palette.mode === 'dark'
                    ? alpha(theme.palette.common.white, 0.08)
                    : alpha(theme.palette.common.black, 0.04),
                },
                position: 'relative',
              }}
            >
              <Badge 
                badgeContent={notificationCount.system} 
                color="error"
                sx={{
                  '& .MuiBadge-badge': {
                    backgroundColor: theme.palette.error.main,
                    color: theme.palette.error.contrastText,
                    fontWeight: 600,
                    minWidth: 20,
                    height: 20,
                    padding: '0 6px',
                    fontSize: '0.75rem',
                  },
                }}
              >
                <FaBell size={16} />
              </Badge>
            </IconButton>
          </Box>

          <Box 
            sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              cursor: 'pointer',
              ml: 1,
              '&:hover': {
                opacity: 0.8
              }
            }}
            onClick={handleMenu}
          >
            <Avatar 
              src={currentUser?.photoURL}
              sx={{ 
                width: 32, 
                height: 32, 
                bgcolor: 'primary.main',
                fontSize: '1rem'
              }}
            >
              {currentUser?.email?.[0].toUpperCase()}
            </Avatar>
            <Typography 
              variant="subtitle1"
              sx={{
                ml: 1,
                display: { xs: 'none', sm: 'block' },
                color: 'text.primary',
                fontWeight: 500
              }}
            >
              {userFullName}
            </Typography>
            <FaChevronDown 
              size={12} 
              style={{ 
                marginLeft: '4px',
                display: 'inline-block',
                verticalAlign: 'middle'
              }} 
            />
          </Box>

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{
              elevation: 1,
              sx: {
                mt: 1,
                minWidth: 200,
                overflow: 'visible',
                boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                borderRadius: 1,
                border: '1px solid',
                borderColor: theme.palette.divider,
              }
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={handleProfile} sx={{ py: 1 }}>
              <ListItemIcon>
                <FaUser size={18} />
              </ListItemIcon>
              <ListItemText 
                primary="Profile"
                primaryTypographyProps={{
                  sx: { fontWeight: 500 }
                }}
              />
            </MenuItem>

            {userRole !== 'landlord' && (
              <MenuItem onClick={handleSettings} sx={{ py: 1 }}>
                <ListItemIcon>
                  <FaCog size={18} />
                </ListItemIcon>
                <ListItemText 
                  primary="Settings"
                  primaryTypographyProps={{
                    sx: { fontWeight: 500 }
                  }}
                />
              </MenuItem>
            )}

            <Divider />

            <MenuItem 
              onClick={handleLogout} 
              sx={{ 
                py: 1,
                color: theme.palette.error.main
              }}
              disabled={logoutLoading}
            >
              <ListItemIcon sx={{ color: theme.palette.error.main }}>
                {logoutLoading ? (
                  <CircularProgress size={18} color="error" />
                ) : (
                  <FaSignOutAlt size={18} />
                )}
              </ListItemIcon>
              <ListItemText 
                primary="Sign Out"
                primaryTypographyProps={{
                  sx: { fontWeight: 500 }
                }}
              />
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
