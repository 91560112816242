import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import { useSidebar } from '../contexts/SidebarContext';
import TopBar from './TopBar';
import Sidebar from './Sidebar';
import { doc, getDoc } from 'firebase/firestore';
import { getFirebaseDb } from '../config/firebase';

export const PrivateRoute = ({ children, excludeRoles = [] }) => {
  const { currentUser } = useAuth();
  const { isCollapsed } = useSidebar();
  const location = useLocation();
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserRole = async () => {
      if (currentUser?.uid) {
        try {
          const db = getFirebaseDb();
          const userRef = doc(db, 'users', currentUser.uid);
          const userSnap = await getDoc(userRef);
          if (userSnap.exists()) {
            setUserRole(userSnap.data().role);
          }
        } catch (error) {
          console.error('Error fetching user role:', error);
        }
      }
      setLoading(false);
    };

    fetchUserRole();
  }, [currentUser]);

  useEffect(() => {
    if (!currentUser) {
      navigate('/login', { state: { from: location }, replace: true });
      return;
    }

    if (!loading && excludeRoles.includes(userRole)) {
      navigate('/dashboard', { replace: true });
    }
  }, [currentUser, loading, userRole, excludeRoles, navigate, location]);

  if (!currentUser || loading || excludeRoles.includes(userRole)) {
    return (
      <Box 
        sx={{ 
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          width: '100vw'
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box 
      sx={{ 
        display: 'flex',
        minHeight: '100vh',
        backgroundColor: 'background.default',
      }}
    >
      <TopBar />
      <Sidebar />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          mt: '64px',
          marginLeft: {
            xs: 0,
            sm: isCollapsed ? '64px' : '260px'
          },
          transition: theme => theme.transitions.create(['margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
