import React, { createContext, useContext, useState, useEffect } from 'react';
import { Snackbar, Alert, Button } from '@mui/material';
import { collection, query, where, onSnapshot, orderBy } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { useAuth } from './AuthContext';

const NotificationContext = createContext();

export function useNotification() {
  return useContext(NotificationContext);
}

export function NotificationProvider({ children }) {
  const [notifications, setNotifications] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [currentNotification, setCurrentNotification] = useState(null);
  const [notificationPermission, setNotificationPermission] = useState('default');
  const { currentUser } = useAuth();

  // Check if Notifications API is supported
  const isNotificationSupported = () => {
    return 'Notification' in window;
  };

  useEffect(() => {
    // Set initial permission state if notifications are supported
    if (isNotificationSupported()) {
      setNotificationPermission(Notification.permission);
    }
  }, []);

  const requestNotificationPermission = async () => {
    try {
      if (!isNotificationSupported()) {
        console.log('Notifications not supported in this browser');
        return 'denied';
      }
      
      const permission = await Notification.requestPermission();
      setNotificationPermission(permission);
      return permission;
    } catch (error) {
      console.error('Error requesting notification permission:', error);
      return 'denied';
    }
  };

  const showNotification = async (message, severity = 'info') => {
    const newNotification = {
      id: Date.now(),
      message,
      severity,
      timestamp: new Date()
    };
    
    setNotifications(prev => [...prev, newNotification]);
    setCurrentNotification(newNotification);
    setOpenSnackbar(true);

    // Show browser notification if permission is granted
    if (notificationPermission === 'granted' && isNotificationSupported()) {
      new Notification('New Message', {
        body: message,
        icon: '/logo192.png'
      });
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const value = {
    notifications,
    showNotification,
    requestNotificationPermission,
    notificationPermission
  };

  return (
    <NotificationContext.Provider value={value}>
      {children}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={currentNotification?.severity || 'info'} 
          sx={{ width: '100%' }}
        >
          {currentNotification?.message}
          {notificationPermission === 'default' && (
            <Button
              size="small"
              color="inherit"
              onClick={requestNotificationPermission}
              sx={{ ml: 2 }}
            >
              Enable Notifications
            </Button>
          )}
        </Alert>
      </Snackbar>
    </NotificationContext.Provider>
  );
}
